import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, Content } from '../components/home/v2/styledComponents';
import Header from '../components/home/v3/Header';
import BenefitsBanner from '../components/home/v3/BenefitsBanner';
import ACouturierAtHome from '../components/home/v3/ACouturierAtHome';
import FeedbacksBannerAds from '../components/home/v3/FeedbacksBannerAds';
import FAQAds from '../components/home/v3/FAQAds';
import MenuBar from '../components/home/MenuBar';
import PartnersPager from '../components/home/v3/PartnersPager';
import Footer from '../components/home/v3/Footer';
import Layout from '../layouts/index';
import { partnersData } from '../components/home/partnersData';

const preFilledStepsOptions = {
  clothes: {
    selectedCategory: 'clothes', selectedTypeOfWork: 'alteration',
  },
  curtains: {
    selectedCategory: 'deco', selectedPiece: 'ride', selectedTypeOfWork: 'alteration',
  },
  suit: {
    selectedCloth: 'cost', selectedCategory: 'clothes', selectedTypeOfWork: 'alteration',
  },
  weddingDress: {
    selectedCloth: 'mari', selectedCategory: 'clothes', selectedTypeOfWork: 'alteration',
  },
};

const LandingPartnersPage = ({
  pageContext: {
    headerTitle, brand, landingPage, backgroundImage, partners, customerFeedback1, customerFeedback2, category,
  },
}) => {
  useEffect(() => {
    localStorage.setItem('landingPage', landingPage);
    localStorage.setItem('landingPage_expiryKey', (new Date()).toISOString());
    localStorage.setItem('brand', brand);
  }, []);

  const preFilledSteps = preFilledStepsOptions[category || 'clothes'];
  const brands = partners.map((partner, index) => {
    const publicURL = partnersData.filter((partnerObject) => partnerObject.key === partner)[0]?.img;
    return ({ index, img: { publicURL } });
  });
  const customerFeedbacks = (customerFeedback1 && customerFeedback2) ? [customerFeedback1, customerFeedback2] : undefined;
  return (
    <Layout>
      <Container>
        <Content full hasNoBanner>
          <MenuBar />
          <Header
            title={headerTitle || 'Nos couturiers se déplacent chez vous, pour vos retouches'}
            hideNote
            smallTitle
            titleLeft
            photoVersion={backgroundImage ? undefined : category}
            curtainsNote={category === 'curtains'}
            preFilledSteps={preFilledSteps}
            partnersBackgroundImage={backgroundImage}

          />
          <BenefitsBanner />
          <ACouturierAtHome
            photoVersion={category}
            curtainsVersion={category === 'curtains'}
            preFilledSteps={preFilledSteps}
            withBottomContainer
          />
          <FeedbacksBannerAds curtainsVersion={category === 'curtains'} customerFeedbacks={customerFeedbacks} />
          <FAQAds curtainsVersion={category === 'curtains'} preFilledSteps={preFilledSteps} />
          <PartnersPager curtainsVersion={category === 'curtains'} brands={brands} />
          <Footer />
        </Content>
      </Container>
    </Layout>
  );
};

LandingPartnersPage.propTypes = {
  pageContext: PropTypes.shape({
    url: PropTypes.string.isRequired,
    headerTitle: PropTypes.string,
    brand: PropTypes.string,
    landingPage: PropTypes.string,
    category: PropTypes.string,
    backgroundImage: PropTypes.shape({}),
    customerFeedback1: PropTypes.shape({
      gatsbyPhoto: PropTypes.shape({}),
      name: PropTypes.string,
      rating: PropTypes.number,
      jobDescription: PropTypes.string,
      feedback: PropTypes.string,
    }),
    customerFeedback2: PropTypes.shape({
      gatsbyPhoto: PropTypes.shape({}),
      name: PropTypes.string,
      rating: PropTypes.number,
      jobDescription: PropTypes.string,
      feedback: PropTypes.string,
    }),
    partners: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default LandingPartnersPage;
